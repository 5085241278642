import React, { useState, useRef } from "react";
import styled from "styled-components";
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
// Assets
import ContactImg1 from "../../assets/img/contact-1.jpg";
import ContactImg2 from "../../assets/img/contact-2.jpg";
import ContactImg3 from "../../assets/img/contact-3.jpg";

export default function Contact() {
  const form = useRef();
  const { t } = useTranslation("common");
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  console.log(process.env.REACT_APP_EMAILJS_USER_ID)
  console.log(process.env.REACT_APP_EMAILJS_TEMPLATE_ID)
  console.log(process.env.REACT_APP_EMAILJS_SERVICE_ID)

  const sendEmail = (e) => {
    e.preventDefault()
    console.log(form.current)

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_USER_ID
    ).then((result) => {
      setName("")
      setEmail("")
      setSubject("")
      setMessage("")
    }, (error) => {
      console.log(error.text)
    });
  }

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t("contact.title")}</h1>
            <p className="font13">
              {t("contact.desc")}
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <form ref={form} onSubmit={sendEmail}>
                  <label className="font13">{t("contact.name")}</label>
                  <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} className="font20 extraBold" />
                  <label className="font13">{t("contact.email")}</label>
                  <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="font20 extraBold" />
                  <label className="font13">{t("contact.subject")}</label>
                  <input type="text" id="subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} className="font20 extraBold" />
                  <textarea rows="4" cols="50" type="text" id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} className="font20 extraBold" />
                  <SumbitWrapper className="flex">
                    <ButtonInput type="submit" value={t("contact.button")} className="pointer animate radius8" style={{ maxWidth: "220px" }} />
                  </SumbitWrapper>
                </form>
              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.div`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #F6A515 !important;
  background-color: #F6A515 !important;
  width: 100% !important;
  padding: 15px !important;
  height: 50px !important;
  outline: none !important;
  color: #fff !important;
  :hover {
    background-color: #580cd2 !important;
    border: 1px solid #F6A515 !important;
    color: #fff !important;
  }
  @media (max-width: 991px) {
    margin: 0 auto !important;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100% !important;
    margin-bottom: 50px !important;
  }
`;









