import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/projects/visit-usa.jpg";
import ProjectImg2 from "../../assets/img/projects/visit-canada.jpg";
import ProjectImg3 from "../../assets/img/projects/visit-luxembourg.jpg";
import ProjectImg4 from "../../assets/img/projects/visit-russia.jpg";
import ProjectImg5 from "../../assets/img/projects/visit-france.jpg";

export default function Tourism() {
  const { t } = useTranslation("common");
  return (
    <Wrapper id="tourism" style={{ marginBottom: "50px" }}>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t("tourism.title")}</h1>
            <p className="font13">
              {t("tourism.desc")}
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title={t("tourism.one.title")}
                text={t("tourism.one.desc")}
                action={() => { }}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title={t("tourism.two.title")}
                text={t("tourism.two.desc")}
                action={() => { }}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title={t("tourism.three.title")}
                text={t("tourism.three.desc")}
                action={() => { }}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title={t("tourism.four.title")}
                text={t("tourism.four.desc")}
                action={() => { }}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title={t("tourism.five.title")}
                text={t("tourism.five.desc")}
                action={() => { }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;